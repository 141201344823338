/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

/* COMPONENTS */
import Layout from '../components/Layout/index'

/* CSS e SCSS */
import './styles/revista-dia.scss'

/* Imagens SVG */
import iconPDF from '../images/icons/001-pdf-file 1.svg'

import PDFOUTUBRO from '../content/RevistaDIA-Ed01-out22.pdf'
import PDFNOVEMBRO from '../content/RevistaDigitalMP- ed02nov.pdf'
import PDFDEZEMBRO from '../content/revista-dezembro.pdf'
import PDFJANEIRO from '../content/REVISTA-MP-DIA-ed04jan23.pdf'
import PDFFEVEREIRO from '../content/DIA_REVISTA_ED05-FEV23.pdf'
import PDFMARCO from '../content/dia-revista-digital-março2023.pdf'
import PDFABRIL from '../content/Revista Digital MP ed.07 Março 2023.pdf'
import PDFMAIO from '../content/Revista Digital MP ed.08 MAIO 2023-2.pdf'
import PDFJUNHO from '../content/RevistaDigitalMPed.09JUNHO2023.pdf'
import PDFJULHO from '../content/revista-julho-2023.pdf'
import PDFAGOSTO from '../content/Revista_Cada_Cada_Dia_Melhor_ED11_Final.pdf'
import PDFOUTUBRO23 from '../content/Revista_Digital_DIA_EDIÇÃO_12_MP_CONCURSO_CULTURAL_750x1334px FINAL.pdf'
import PDFDEZEMBRO23 from '../content/Revista CADA DIA MELHOR Edição 13_dezembro.pdf'
import PDFFEVEREIRO24 from '../content/Revista Marca Propria - Fevereiro (2).pdf'

export default function Revistas(props) {
  const breadC = {
    visible: true,
    color: 'dark',
    items: [
      { path: '/', name: 'Home' },
      { path: '/revista-dia/', name: 'Revistas DIA' }
    ]
  }
  const [selectedOption, setSelectedOption] = useState('2024')
  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Revistas - Dia Supermercados</title>
        <meta name="description" content="Cada dia melhor a revista da marca própria DIA. Baixe agora!" />
        <meta name="keywords" content="revista, revistas" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <section>
        <div className="bg-revistas bg-dia-gray">
          <div className="container">
            <div className="row d-flex justify-content-center pt-lg-0 pt-2 align-items-lg-end" style={{ height: '200px' }}>
              <div className="col-lg-9 col-12 text-lg-left text-center">
                <h2 className="text-blue text-lojas mt-lg-0 mt-5 mb-lg-4 mb-0">Cada Dia Melhor<br></br> A Revista da Marca Própria DIA</h2>
              </div>
              <div className="col-lg-3 col-12 pb-5 pt-2 d-flex justify-content-center">
                <select value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
                  <option selected value="2024">2024</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className='revistas container py-5'>
          <span className='span'>Confira nossas revistas:</span>
          {selectedOption === '2024' &&
            <div className='row pt-3'>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaFevereiro2024.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                  style={{ width: '99.5%' }}
                />
                <span>Edição 14 - Fevereiro de 2024</span>
                <a href={PDFFEVEREIRO24} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
            </div>
          }
          {selectedOption === '2023' &&
            <div className='row pt-3'>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaDezembro2023.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                  style={{ width: '99.5%' }}
                />
                <span>Edição 13 - Dezembro de 2023</span>
                <a href={PDFDEZEMBRO23} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaOutubro23.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                  style={{ width: '99.5%' }}
                />
                <span>Edição 12 - Outubro de 2023</span>
                <a href={PDFOUTUBRO23} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaAgosto.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                  style={{ width: '99.5%' }}
                />
                <span>Edição 11 - Agosto de 2023</span>
                <a href={PDFAGOSTO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaJulho.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                  style={{ width: '99.5%' }}
                />
                <span>Edição 10 - Julho de 2023</span>
                <a href={PDFJULHO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaJunho.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                  style={{ width: '99.5%' }}
                />
                <span>Edição 9 - Junho de 2023</span>
                <a href={PDFJUNHO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaMaio.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                  style={{ width: '103%' }}
                />
                <span>Edição 8 - Maio de 2023</span>
                <a href={PDFMAIO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaAbril.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                />
                <span>Edição 7 - Abril de 2023</span>
                <a href={PDFABRIL} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaMarco.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                />
                <span>Edição 6 - Março de 2023</span>
                <a href={PDFMARCO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaFevereiro.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                />
                <span>Edição 5 - Fevereiro de 2023</span>
                <a href={PDFFEVEREIRO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaJaneiro.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                />
                <span>Edição 4 - Janeiro de 2023</span>
                <a href={PDFJANEIRO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
            </div>
          }
          {selectedOption === '2022' &&
            <div className='row pt-3'>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaDezembro.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                />
                <span>Edição 3 - Dezembro de 2022</span>
                <a href={PDFDEZEMBRO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaNovembro.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                />
                <span>Edição 2 - Novembro de 2022</span>
                <a href={PDFNOVEMBRO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
              <div className='col-lg-2 col-6 pb-4'>
                <GatsbyImage
                  image={props.data.revistaOutubro.childImageSharp.gatsbyImageData}
                  alt="Capa da revista"
                  className='shadow radius mb-1'
                />
                <span>Edição 1 - Outubro de 2022</span>
                <a href={PDFOUTUBRO} target="_blank" rel="noopener noreferrer" download>
                  <button className='btn btn-red2 w-100 mt-1'><img src={iconPDF} alt="Icone de pdf" className="pr-2" width='28px' />Baixar Revista</button>
                </a>
              </div>
            </div>
          }
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`{
  revistaOutubro23: file(relativePath: {eq: "revista-dia/revista-outubro-2023.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaAgosto: file(relativePath: {eq: "revista-dia/revista-aniversario-2023.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaJulho: file(relativePath: {eq: "revista-dia/revista-julho-2023.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaJunho: file(relativePath: {eq: "revista-dia/revista-junho.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaOutubro: file(relativePath: {eq: "revista-dia/revista-outubro.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaNovembro: file(relativePath: {eq: "revista-dia/revista-novembro.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaDezembro: file(relativePath: {eq: "revista-dia/revista-dezembro.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaJaneiro: file(relativePath: {eq: "revista-dia/revista-janeiro.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaFevereiro: file(relativePath: {eq: "revista-dia/revista-fevereiro.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaMarco: file(relativePath: {eq: "revista-dia/revista-marco.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaAbril: file(relativePath: {eq: "revista-dia/revista-abril.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaMaio: file(relativePath: {eq: "revista-dia/revista-maio.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaDezembro2023: file(relativePath: {eq: "revista-dia/revista-dezembro-2023.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  revistaFevereiro2024: file(relativePath: {eq: "revista-dia/capa-carnaveal.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
